/* src/index.css or src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Font Setting */
body {
  font-family: 'Montserrat', sans-serif;
}

/* App Base Styles */
.App {
  text-align: center;
}

/* App Logo Animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@media (prefers-reduced-motion: reduce) {
  .App-logo {
    animation: none;
  }
}

/* Header Styling */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Arial Greek", Arial, sans-serif; /* Ensures header uses the specified font */
}

/* Links Styling */
.App-link {
  color: #61dafb;
  transition: color 0.2s ease;
  font-family: "Arial Greek", Arial, sans-serif;
}

.App-link:hover {
  color: #2563eb; /* Tailwind Blue */
}

/* App Logo Spin Keyframes */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Utility Classes */
.disable-download img {
  pointer-events: none;
}

.transition-enabled {
  opacity: 0;
}

.transition-in {
  animation: fade-in 0.25s ease-in forwards;
}

.transition-out {
  animation: fade-out 0.25s ease-out forwards;
}

/* Fade-In and Fade-Out Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}