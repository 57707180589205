/* Set Lexend as the base font */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Apply specific styles if needed */
.name-text,
.about-text,
.text-gray-600 {
  font-family: 'Montserrat', sans-serif;
}

/* Profile Image Styles */
.profile-image-container {
  animation: float 4s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.disable-download img {
  pointer-events: none;
}

/* Spinner Animation */
@-webkit-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lightbox-spinner {
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 30px;
  width: 30px;
  border: 4px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.7;
}

/* Link Transition */
.link-transition a {
  transition: background 0.2s ease, color 0.2s ease;
  font-family: "Arial Greek", Arial, sans-serif; /* Greek font for links */
}

.link-transition a:hover {
  color: #2563eb; /* Blue hover effect */
}

.link-transition a svg {
  transition: fill 0.2s ease;
}

/* Fade-In and Fade-Out Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.transition-enabled {
  opacity: 0;
}

.transition-in {
  animation: fade-in 0.25s ease-in forwards;
}

.transition-out {
  animation: fade-out 0.25s ease-out forwards;
}

/* Zoomable Content */
.lightbox-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  transition: opacity 0.4s;
  font-family: "Arial Greek", Arial, sans-serif;
}

html.lightbox-zoomed .lightbox-content {
  cursor: zoom-out;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .profile-image-container {
    animation: float 6s ease-in-out infinite;
  }
}

@media (min-width: 1024px) {
  .profile-image-container {
    animation: float 4s ease-in-out infinite;
  }
}

/* styles.css */

/* Section Title Styling */
.section-title {
  position: relative;
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2d3748; /* text-gray-800 */
  margin-bottom: 1.5rem;
}

.section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.25rem;
  width: 50%;
  height: 0.5rem;
  background-color: #63b3ed; /* blue-400 */
  opacity: 0.5;
  z-index: -1;
}

/* Bounce Animation */
@keyframes bounceAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounceAnimation 1.5s ease-in-out infinite;
}

/* Optional: Smooth Transition for Image */
#profile-image {
  transition: transform 0.3s ease-in-out;
}

/* Responsive Adjustments (Optional) */
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  #profile-image {
    width: 48px;
    height: 48px;
  }
}